import { useState, useLayoutEffect, RefObject } from 'react';

export function useScrollTop(ref: RefObject<HTMLDivElement>) {
	const [scrollTop, setScrollTop] = useState<number>(
		ref.current?.scrollTop ?? 0
	);

	useLayoutEffect(() => {
		const localRef = ref?.current;
		function handleChanges() {
			setScrollTop(localRef?.scrollTop ?? 0);
		}

		localRef?.addEventListener('resize', handleChanges);
		// TODO: Debounce this
		localRef?.addEventListener('scroll', handleChanges);

		return () => {
			localRef?.removeEventListener('resize', handleChanges);
			localRef?.removeEventListener('scroll', handleChanges);
		};
	}, [ref]);

	return scrollTop;
}
