import { icons } from './icons';

type IconType = keyof typeof icons;

export const Icon = ({
	name,
	width,
	style = {},
	...props
}: {
	name: IconType;
	style?: { [key: string]: string };
	width: number | string;
}) => {
	const className = `Icon Icon--${name}`;
	const IconComponent = icons[name];
	const styles = { ...style, width };

	return (
		<div className={className} style={styles} {...props}>
			<IconComponent />
		</div>
	);
};
