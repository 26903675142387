import './FiveWideImage.scss';

export const FiveWideImage = ({ image }: { image: string }) => {
	return (
		<div className="FiveWideImage">
			<div className="FiveWideImage__Content">
				<div className="FiveWideImage__Image">
					<img src={image} alt="" />
				</div>
			</div>
		</div>
	);
};
