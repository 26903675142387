import './SlideIn.scss';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { IconBack } from '../IconBack/IconBack';
import { noop } from '../../utils/noop';
import { createPortal } from 'react-dom';
import { ReactNode, useLayoutEffect, useRef } from 'react';
import { usePortal } from '../../utils/usePortal';
// import { useTransition } from 'react-spring';

export const SlideIn = ({
	children,
	show,
	headerContent = null,
	onClose = noop,
}: {
	children: React.ReactNode;
	show: boolean;
	headerContent?: ReactNode;
	onClose?: () => void;
}) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const portal = usePortal('root');

	const visibilityClass = show ? 'is-visible' : 'is-hidden';

	useLayoutEffect(() => {
		if (!contentRef.current) {
			return;
		}

		if (show) {
			// Disable scrolling behind the element
			disableBodyScroll(contentRef.current, { reserveScrollBarGap: true });
		} else {
			// Re-enable scrolling
			enableBodyScroll(contentRef.current);
		}
	});

	return (
		<>
			{createPortal(
				<>
					<div
						className={`SlideIn__Overlay ${visibilityClass}`}
						onClick={onClose}
					></div>
					<div className={`SlideIn ${visibilityClass}`}>
						<div className="SlideIn__Header">
							<div className="SlideIn__CloseButton" onClick={onClose}>
								<IconBack shadow={false} /> Close
							</div>
							<div className="SlideIn__HeaderContent">{headerContent}</div>
						</div>
						<div className="SlideIn__Content" ref={contentRef}>
							{children}
						</div>
					</div>
				</>,
				portal
			)}
		</>
	);
};
