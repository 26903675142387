import './Video.scss';

import { useRef, useState } from 'react';

export const Video = ({
	urlMp4,
	loop = false,
}: {
	urlMp4: string;
	loop?: boolean;
}) => {
	const video = useRef<HTMLVideoElement>(null);
	const [isPlaying, setIsPlaying] = useState(true);

	const handleClick = () => {
		if (video === null || video.current === null) {
			return;
		}

		if (isPlaying) {
			video.current.pause();
			setIsPlaying(false);
		} else {
			video.current.play();
			setIsPlaying(true);
		}
	};

	const className = `Video Video--${isPlaying ? 'is-playing' : 'is-paused'}`;

	return (
		<div className={className}>
			<div className="Video__PlayButton"></div>
			<video
				controls={false}
				loop={loop}
				muted={true}
				autoPlay={true}
				playsInline={true}
				style={{ width: '100%' }}
				ref={video}
				onClick={handleClick}
			>
				<source src={urlMp4} type="video/mp4" />
			</video>
		</div>
	);
};
