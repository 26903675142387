import { ZoomImage } from '../../../../components/ZoomImage/ZoomImage';
import './TwoSquareImage.scss';

export const TwoSquareImage = ({
	image,
	image_zoom,
}: {
	image: string;
	image_zoom: string;
}) => {
	return (
		<div className="TwoSquareImage">
			<div className="TwoSquareImage__Content">
				<div className="TwoSquareImage__Image">
					<ZoomImage
						image={image}
						zoomImage={image_zoom}
						originalWidth={500}
						originalHeight={500}
					/>
				</div>
			</div>
		</div>
	);
};
