import { Globals } from '../../api/login';
import './Footer.scss';

export const Footer = ({
	title,
	socialMedia,
}: {
	title: string;
	socialMedia?: Globals['socal_media'];
}) => {
	return (
		<div className="Footer">
			<div className="Footer__Title">
				<div className="Footer__TitleA">{title}</div>
				<div className="Footer__TitleB">
					{/*by */}Kristensen &amp; Kristensen
				</div>
			</div>
			{socialMedia && (
				<div className="Footer__SocialProfiles">
					<div className="Footer__SocialProfilesLabel">Follow us: </div>

					{socialMedia.map(({ label, link }) => (
						<div className="Footer__SocialProfile" key={label}>
							<a href={link}>{label}</a>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
