import './Hero.scss';

import { Series } from '../../../api/login';
import { IconBack } from '../../../components/IconBack/IconBack';
import { noop } from '../../../utils/noop';
import { use100vh } from 'react-div-100vh';
import { MenuIcon } from '../../../components/MenuIcon/MenuIcon';

export const Hero = ({
	series,
	onCloseClick = noop,
	onMenuClick = noop,
}: {
	series: Series;
	onCloseClick?: () => void;
	onMenuClick?: () => void;
}) => {
	const height = use100vh() ?? '100vh'; // Get the true browser height

	const backgroundImage = `url(${series.hero_image})`;

	return (
		<div className="Hero" style={{ backgroundImage, height }}>
			<div className="Hero__Inner">
				<div className="Hero__Content">
					<div
						className="Hero__ContentTitle"
						dangerouslySetInnerHTML={{ __html: series.title }}
					></div>
					<div className="Hero__ContentSubtitle">{series.subtitle}</div>
					<div className="Hero__ContentLine"></div>
					<div className="Hero__ContentBackLink" onClick={onCloseClick}>
						<IconBack />
						Back
					</div>
				</div>
				<div className="Hero__MenuLink" onClick={() => onMenuClick()}>
					<MenuIcon />
				</div>
			</div>
		</div>
	);
};
