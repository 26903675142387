import { YoutubeEmbed } from '../../../../components/YoutubeEmbed/YoutubeEmbed';
import './NineYoutubeVideo.scss';

export const NineYoutubeVideo = ({ youtubeid, cover, heading = '' }: { youtubeid: string; cover: string; heading: string; }) => {
    return (
        <div className="NineYoutubeVideo">

            {!!(heading) ? (
                <div className="NineYoutubeVideo__Heading">
                    {heading}
                </div>
            ) : ('')}

            <div className="NineYoutubeVideo__Video">
                <YoutubeEmbed
                    youtubeid={youtubeid}
                    cover={cover}
                />
            </div>
        </div>
    );
};
