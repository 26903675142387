import { Video } from '../../../../components/Video/Video';
import './SixShortDemoVideo.scss';

export const SixShortDemoVideo = ({ video }: { video: string }) => {
	return (
		<div className="SixShortDemoVideo">
			<div className="SixShortDemoVideo__Video">
				<Video urlMp4={video} loop={true} />
			</div>
		</div>
	);
};
