import { CSSProperties, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { animated } from 'react-spring';
import { noop } from '../../utils/noop';
import { usePortal } from '../../utils/usePortal';
import './StickyLink.scss';

export const StickyLink = ({
	icon = false,
	label,
	onClick = noop,
	style = {},
	darkmode = false,
}: {
	icon: ReactElement | false;
	label: string;
	onClick: () => void;
	style: CSSProperties;
	darkmode: boolean;
}) => {
	const portal = usePortal('root');

	return (
		<>
			{createPortal(
				<animated.div
					className={`Hero__SidebarLink${darkmode ? ' has-darkmode' : ''}`}
					onClick={() => onClick()}
					style={style}
				>
					<div className="Hero__SidebarLinkText">{label}</div>
					<div className="Hero__SidebarIcon">{icon}</div>
				</animated.div>,
				portal
			)}
		</>
	);
};
