import React, { useState } from 'react';

export const Tabs = ({ children }: { children: React.ReactNodeArray }) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const selectTab = (index: number) => {
		setActiveTabIndex(index);
	};

	const getTabMenuItemClass = (isSelected: boolean) => {
		return 'Tabs__menu-item' + (isSelected ? ' is-selected' : '');
	};

	const getTabMenu = () => {
		if (
			!children ||
			typeof children === 'string' ||
			typeof children === 'number' ||
			typeof children === 'boolean'
		) {
			return <></>;
		}

		return children
			.map((tab, i) => {
				if (tab) {
					return (
						<div
							className={getTabMenuItemClass(i === activeTabIndex)}
							key={i}
							onClick={() => selectTab(i)}
						>
							{/*@ts-ignore*/}
							{tab.props.name}
						</div>
					);
				} else {
					return null;
				}
			})
			.filter((component) => component !== null);
	};

	return (
		<div className="Tabs">
			<div className="Tabs__menu">{getTabMenu()}</div>
			<div className="Tabs__tab">{children[activeTabIndex]}</div>
		</div>
	);
};
