import './FourTripleImageReflected.scss';

import { ZoomImage } from '../../../../components/ZoomImage/ZoomImage';

export const FourTripleImageReflected = ({
	image1,
	image1_zoom,
	image2,
	image2_zoom,
	image3,
	image3_zoom,
	text,
}: {
	image1: string;
	image1_zoom: string;
	image2: string;
	image2_zoom: string;
	image3: string;
	image3_zoom: string;
	text: string;
}) => {
	return (
		<div className="FourTripleImageReflected">
			<div className="FourTripleImageReflected__Row">
				<div className="FourTripleImageReflected__Col1">
					<div className="FourTripleImageReflected__Image1">
						<ZoomImage
							image={image1}
							zoomImage={image1_zoom}
							originalWidth={500}
							originalHeight={500}
						/>
					</div>
				</div>
				<div className="FourTripleImageReflected__Col2">
					<div className="FourTripleImageReflected__Image2">
						<ZoomImage
							image={image2}
							zoomImage={image2_zoom}
							originalWidth={500}
							originalHeight={500}
						/>
					</div>
					<div
						className="FourTripleImageReflected__Text"
						dangerouslySetInnerHTML={{ __html: text }}
					></div>
				</div>
				<div className="FourTripleImageReflected__Col3">
					<div className="FourTripleImageReflected__Image3">
						<ZoomImage
							image={image3}
							zoomImage={image3_zoom}
							originalWidth={500}
							originalHeight={500}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
