import { ZoomImage } from '../../../../components/ZoomImage/ZoomImage';
import './OneImageAndText.scss';

export const OneImageAndText = ({
	image,
	image_zoom,
	text,
}: {
	image: string;
	image_zoom: string;
	text: string;
}) => {
	return (
		<div className="OneImageAndText">
			<div className="OneImageAndText__Content">
				<div className="OneImageAndText__Image">
					<ZoomImage
						image={image}
						zoomImage={image_zoom}
						originalWidth={900}
						originalHeight={580}
					/>
				</div>
				<div
					className="OneImageAndText__Text"
					dangerouslySetInnerHTML={{ __html: text }}
				></div>
			</div>
		</div>
	);
};
