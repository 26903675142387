import './SeriesCard.scss';

import { noop } from '../../utils/noop';

export const SeriesCard = ({
	image,
	title,
	subtitle,
	onClick = noop,
}: {
	image: string;
	title: string;
	subtitle: string;
	onClick: () => void;
}) => {
	// View data
	const backgroundImage = `url(${image})`;

	return (
		<div className="SeriesCard" style={{ backgroundImage }} onClick={onClick}>
			<div className="SeriesCard__Content">
				<div className="SeriesCard__Title" dangerouslySetInnerHTML={{ __html: title }}></div>
				<div className="SeriesCard__Subtitle">{subtitle}</div>
				<div className="SeriesCard__Line"></div>
			</div>
		</div>
	);
};
