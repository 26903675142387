import './SevenLargeText.scss';

export const SevenLargeText = ({ text }: { text: string }) => {
	return (
		<div className="SevenLargeText">
			<div
				className="SevenLargeText__Content"
				dangerouslySetInnerHTML={{ __html: text }}
			></div>
		</div>
	);
};
