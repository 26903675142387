import './Overview.scss';

import { SeriesCard } from '../../components/SeriesCard/SeriesCard';
import { Series } from '../../api/login';
import { noop } from '../../utils/noop';

export const Overview = ({
	series = [],
	onSeriesClick = noop,
}: {
	series?: Series[];
	onSeriesClick: (series: Series) => void;
}) => {
	return (
		<div className="Overview">
			{series.map((series) => (
				<div className="Overview__Card" key={series.id}>
					<SeriesCard
						image={series.overview}
						title={series.title}
						subtitle={series.subtitle}
						onClick={() => onSeriesClick(series)}
					/>
				</div>
			))}
		</div>
	);
};
