import { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useHistory } from 'react-router-dom';
import { getLoginPage } from '../../api/getLoginPage';
import { LoginPage, LoginReturnValue } from '../../api/login';
import { LoginBox } from '../../components/LoginBox/LoginBox';
import './Login.scss';

export const Login = ({
	onLogin,
}: {
	onLogin: (data: LoginReturnValue) => void;
}) => {
	const history = useHistory();
	const height = use100vh() ?? '50vh';

	const [pageData, setPageData] = useState<LoginPage>();

	// Get page data
	useEffect(() => {
		const fetchData = async () => {
			const data = await getLoginPage();
			setPageData(data);
		};

		if (!pageData) {
			fetchData();
		}
	});

	const handleLogin = (data: LoginReturnValue) => {
		onLogin(data);
		history.push('/overview');
	};

	return (
		<>
			{pageData && (
				<div className="Login" style={{ height }}>
					<div className="Login__Background">
						<video
							controls={false}
							loop={true}
							muted={true}
							autoPlay={true}
							playsInline={true}
							style={{ width: '100%' }}
						>
							<source src={pageData.bg_video_webm} type="video/webm" />
							<source src={pageData.bg_video_mp4} type="video/mp4" />
							<source src={pageData.bg_video_jpg} type="image/jpg" />
						</video>
					</div>
					<div className="Login__Inner">
						<div className="Login__Content">
							<div className="Login__ContentCompany">
								Kristensen &amp; Kristensen
							</div>
							<div className="Login__ContentDivider"></div>
							<div className="Login__ContentHeadline">{pageData.title}</div>
						</div>
						<div className="Login__PasswordBox">
							<LoginBox onLogin={handleLogin} />
						</div>
					</div>
				</div>
			)}
		</>
	);
};
