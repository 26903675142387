import { api } from './api';

export interface LoginPage {
	title: string;
	bg_video_mp4: string;
	bg_video_webm: string;
	bg_video_jpg: string;
}

export async function getLoginPage(): Promise<LoginPage> {
	const result = await api.get('login-page').then(({ data }) => data);

	if (result.error) {
		throw new Error(result.error);
	}

	return result;
}
