import { Series } from '../../api/login';
import './ContactList.scss';

const Contact = ({
	name,
	email,
	job_title,
	phone,
	workplace,
}: {
	name: string;
	email: string;
	job_title: string;
	phone: string;
	workplace: string;
}) => {
	return (
		<div className="ContactList__Contact">
			<div className="ContactList__ContactGroup">
				<div
					className="ContactList__ContactName"
					dangerouslySetInnerHTML={{ __html: name }}
				></div>
				<div className="ContactList__ContactJobTitle">{job_title}</div>
				<div className="ContactList__ContactWorkplace">{workplace}</div>
			</div>
			<div className="ContactList__ContactGroup">
				<div className="ContactList__ContactPhone">
					Tel: <a href={`tel:${phone}`}>{phone}</a>
				</div>
				{/* <div className="ContactList__ContactEmail" title={email}>
					E-mail: {email}
				</div> */}
				<div className="ContactList__ContactMailLink">
					Email: <a href={`mailto:${email}`}> Send email</a>
				</div>
			</div>
		</div>
	);
};

export const ContactList = ({ contacts }: { contacts: Series['contacts'] }) => {
	const { kristensen_contacts, local_contacts } = contacts;

	return (
		<div className="ContactList">
			{local_contacts.length > 0 && (
				<div className="ContactList__Group">
					<div className="ContactList__GroupHeader">Local Representative</div>
					<div className="ContactList__GoupList">
						{local_contacts.map((contact) => (
							<Contact {...contact} key={contact.name} />
						))}
					</div>
				</div>
			)}
			{kristensen_contacts.length > 0 && (
				<div className="ContactList__Group">
					<div className="ContactList__GroupHeader">
						Kristensen &amp; Kristensen
					</div>
					<div className="ContactList__GoupList">
						{kristensen_contacts.map((contact) => (
							<Contact {...contact} key={contact.name} />
						))}
					</div>
				</div>
			)}
		</div>
	);
};
