import './Content.scss';
import { Series } from '../../../api/login';
import { SixShortDemoVideo } from './Blocks/SixShortDemoVideo';
import { ThreeTripleImage } from './Blocks/ThreeTripleImage';
import { FourTripleImageReflected } from './Blocks/FourTripleImageReflected';
import { OneImageAndText } from './Blocks/OneImageAndText';
import { TwoSquareImage } from './Blocks/TwoSquareImage';
import { FiveWideImage } from './Blocks/FiveWideImage';
import { SevenLargeText } from './Blocks/SeventLargeText';
import { EightImageAndContent } from './Blocks/EightImageAndContent';
import { NineYoutubeVideo } from './Blocks/NineYoutubeVideo';

export const Content = ({
	contentBlocks,
}: {
	contentBlocks: Series['content_blocks'];
}) => {
	return (
		<div className="Content">
			{contentBlocks.map((block, i) => {
				switch (block.acf_fc_layout) {
					case 'content_block_1_-_image_and_text':
						return (
							<OneImageAndText
								image={block.image.cropped}
								image_zoom={block.image.zoom}
								text={block.text}
								key={i}
							/>
						);

					case 'content_block_2_square_image':
						return (
							<TwoSquareImage
								image={block.image.cropped}
								image_zoom={block.image.zoom}
								key={i}
							/>
						);

					case 'content_block_3_triple_image':
						return (
							<ThreeTripleImage
								image1={block.image_1.cropped}
								image1_zoom={block.image_1.zoom}
								image2={block.image_2.cropped}
								image2_zoom={block.image_2.zoom}
								image3={block.image_3.cropped}
								image3_zoom={block.image_3.zoom}
								text={block.text}
								key={i}
							/>
						);

					case 'content_block_4_triple_image_reflected':
						return (
							<FourTripleImageReflected
								image1={block.image_1.cropped}
								image1_zoom={block.image_1.zoom}
								image2={block.image_2.cropped}
								image2_zoom={block.image_2.zoom}
								image3={block.image_3.cropped}
								image3_zoom={block.image_3.zoom}
								text={block.text}
								key={i}
							/>
						);

					case 'content_block_5_wide_image':
						return <FiveWideImage image={block.image.cropped} key={i} />;

					case 'content_block_6_short_demo_video':
						return <SixShortDemoVideo video={block.video} key={i} />;

					case 'content_block_7_large_text':
						return <SevenLargeText text={block.text} key={i} />;

					case 'content_block_8_image_and_content':
						return (
							<EightImageAndContent
								title={block.title}
								subtitle={block.subtitle}
								text={block.text}
								image={block.image.cropped}
								image_zoom={block.image.zoom}
								key={i}
							/>
						);

					case 'content_block_9_-_youtube':
						return <NineYoutubeVideo youtubeid={block.youtubeid} cover={block.cover.cropped} heading={block.heading} />;
				}
				return <></>;
			})}
		</div>
	);
};
