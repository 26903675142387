import './YoutubeEmbed.scss';

import { useRef, useState } from 'react';
import YouTube from 'react-youtube';

export const YoutubeEmbed = ({
	youtubeid,
	cover
}: {
	youtubeid: string;
	cover: string;
}) => {

	const player = useRef<YouTube>(null)
	const [isPlaying, setIsPlaying] = useState(false);

	const handleClick = () => {
		if (player && player.current) {
			player.current.getInternalPlayer().playVideo()
		}
	};

	const play = () => {
		setIsPlaying(true);
	}

	const stop = () => {
		setIsPlaying(false);
	}

	const className = `YoutubeVideo YoutubeVideo--${isPlaying ? 'is-playing' : 'is-paused'}`;

	return (
		<div className={className}>
			<div className="YoutubeVideo__Video">
				<YouTube
					videoId={youtubeid}
					onPlay={play}
					onPause={stop}
					onEnd={stop}
					ref={player}
				/>
			</div>
			<div className="YoutubeVideo__Cover" style={{ backgroundImage: `url(${cover})` }} onClick={handleClick}>
				<div className="YoutubeVideo__PlayButton"></div>
			</div>
		</div>
	);
};
