import { NavLink } from 'react-router-dom';

import { Series } from '../../api/login';
import { noop } from '../../utils/noop';

import './MenuSlideIn.scss';

export const MenuSlideIn = ({
	series,
	onSelect = noop,
}: {
	series: Series[];
	onSelect?: () => void;
}) => {
	const sortedSeries = [...series].sort((a, b) =>
		a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
	);

	return (
		<div className="MenuSlideIn">
			{sortedSeries.map((serie) => (
				<NavLink
					to={`/series/${serie.id}`}
					key={serie.id}
					className="MenuSlideIn__Link"
					onClick={() => onSelect()}
				>
					<div
						className="MenuSlideIn__LinkInner"
						dangerouslySetInnerHTML={{ __html: serie.title }}
					></div>
				</NavLink>
			))}
		</div>
	);
};
