import { useState } from 'react';
import { createPortal } from 'react-dom';
import { animated, useTransition } from 'react-spring';
import { noop } from '../../utils/noop';
import { urlify } from '../../utils/urlify';
import { usePortal } from '../../utils/usePortal';
import { springDelay } from '../../utils/springDelay';
import './ZoomImage.scss';

const ZoomPopup = ({
	image,
	show = false,
	onClick = noop,
}: {
	image: string;
	show: boolean;
	onClick: () => void;
}) => {
	const scaleTransitions = useTransition(show, null, {
		from: { opacity: 0, transform: 'scale(0.5)' },
		// @ts-ignore
		enter: springDelay(300)({ opacity: 1, transform: 'scale(1)' }),
		leave: { opacity: 0, transform: 'scale(0.5)', pointerEvents: 'none' },
	});

	return (
		<>
			{scaleTransitions.map(
				({ item, key, props }) =>
					item && (
						<animated.div
							key={key}
							style={props}
							className="ZoomImagePopup"
							onClick={onClick}
						>
							<div
								className="ZoomImagePopup__Image"
								style={{ backgroundImage: urlify(image) }}
							></div>
						</animated.div>
					)
			)}
		</>
	);
};

export const ZoomImage = ({
	image,
	zoomImage,
	originalWidth,
	originalHeight,
}: {
	image: string;
	zoomImage: string;
	originalWidth: number;
	originalHeight: number;
}) => {
	const [isZoomed, setIsZoomed] = useState(false);

	const popupPortal = usePortal('root');

	const proportions = (originalHeight / originalWidth) * 100;

	const handleClick = () => {
		setIsZoomed(!isZoomed);
	};

	const fadeTransitions = useTransition(isZoomed, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return (
		<>
			<div
				className="ZoomImage"
				style={{
					paddingBottom: `${proportions}%`,
					backgroundImage: urlify(image),
				}}
				onClick={handleClick}
			></div>
			{createPortal(
				<>
					<ZoomPopup
						show={isZoomed}
						image={zoomImage}
						onClick={() => setIsZoomed(false)}
					/>
					{fadeTransitions.map(
						({ item, key, props }) =>
							item && (
								<animated.div
									key={key}
									style={props}
									className="ZoomImageOverlay"
									onClick={() => setIsZoomed(false)}
								></animated.div>
							)
					)}
				</>,
				popupPortal
			)}
		</>
	);
};
