import React, { useState } from 'react';
import {
	Switch,
	Route,
	useHistory,
	Redirect,
	useRouteMatch,
} from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { Series, LoginReturnValue } from './api/login';
import { MenuIcon } from './components/MenuIcon/MenuIcon';
import { MenuSlideIn } from './components/MenuSlideIn/MenuSlideIn';
import { SlideIn } from './components/SlideIn/SlideIn';
import { Login } from './pages/Login/Login';
import { Overview } from './pages/Overview/Overview';
import { SeriesPage } from './pages/SeriesPage/SeriesPage';
import { springDelay } from './utils/springDelay';

export default function App() {
	const [showMenu, setShowMenu] = useState(false);
	const [siteData, setSiteData] = useState<LoginReturnValue | null>(null);
	const history = useHistory();

	function handleLogin(data: LoginReturnValue) {
		setSiteData(data);
	}

	function handleSelect(series: Series) {
		history.push(`/series/${series.id}`);
	}

	function handleClose() {
		history.push('/overview');
	}

	if (siteData === null) {
		console.log('No site data, trying session storage');
		const sessionData = sessionStorage.getItem('siteData');

		if (sessionData) {
			console.log('Got data from session storage');

			setSiteData(JSON.parse(sessionData));
		}
	}

	// Match route for series pages
	const routeMatch = useRouteMatch<{ seriesId: string }>('/series/:seriesId');

	// Filter the chosen series (if any) to the id from the matched route
	const chosenSeries = siteData
		? siteData.series.filter(
				(series) => series.id === parseInt(routeMatch?.params?.seriesId ?? '-1')
		  )
		: [];

	const seriesTransitions = useTransition(chosenSeries, (series) => series.id, {
		from: { opacity: 0, transform: 'scale(0.5)' },
		leave: { opacity: 0, transform: 'scale(0.5)' },
		// @ts-ignore
		enter: springDelay(300)({ opacity: 1, transform: 'scale(1)' }),
	});

	return (
		<>
			{seriesTransitions.map(({ item, props, key }) => (
				<animated.div
					key={key}
					style={{
						...props,
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 10000,
					}}
				>
					<SeriesPage
						series={item}
						collectiontitle={siteData?.globals.collection_title}
						socialMedia={siteData?.globals.socal_media}
						onClose={handleClose}
						onMenuClick={() => setShowMenu(true)}
					/>
				</animated.div>
			))}
			<Switch>
				<Route path={['/overview', '/series/:seriesId']}>
					{!siteData && <Redirect to={{ pathname: '/' }} />}

					<Overview series={siteData?.series} onSeriesClick={handleSelect} />
					<div
						className="App__StickyMenuLink"
						onClick={() => setShowMenu(true)}
					>
						<MenuIcon />
					</div>
				</Route>
				<Route exact path="/">
					{siteData && <Redirect to={{ pathname: '/overview' }} />}
					{!siteData && <Login onLogin={handleLogin} />}
				</Route>
			</Switch>
			{siteData && (
				<SlideIn
					show={showMenu}
					onClose={() => setShowMenu(false)}
					headerContent={
						<a
							href={siteData.globals.privacy_policy_url}
							className="MenuSlideIn__PrivacyPolicyLink"
						>
							Privacy policy
						</a>
					}
				>
					<MenuSlideIn
						series={siteData.series}
						onSelect={() => setShowMenu(false)}
					/>
				</SlideIn>
			)}
		</>
	);
}
