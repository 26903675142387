import './IconBack.scss';

export const IconBack = ({ shadow = true }: { shadow?: boolean }) => {
	const className = `IconBack${shadow ? ' has-shadow' : ''}`;

	return (
		<div className={className}>
			<div className="IconBack__Inner"></div>
		</div>
	);
};
