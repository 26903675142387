import './SeriesPage.scss';

import { Globals, Series } from '../../api/login';
import { Hero } from './Hero/Hero';
import { Content } from './Content/Content';
import { Footer } from '../../components/Footer/Footer';
import { useRef, useState } from 'react';
import { SlideIn } from '../../components/SlideIn/SlideIn';
import { SeriesInfoSlideIn } from '../../components/SeriesInfoSlideIn/SeriesInfoSlideIn';
import { noop } from '../../utils/noop';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useScrollTop } from '../../utils/useScrollTop';
import { useTransition } from 'react-spring';
import { Icon } from '../../components/Icon/Icon';
import { StickyLink } from '../../components/StickyLink/StickyLink';
import { springDelay } from '../../utils/springDelay';

export const SeriesPage = ({
	series,
	socialMedia = [],
	collectiontitle = '',
	onClose = noop,
	onMenuClick = noop,
}: {
	series: Series;
	socialMedia?: Globals['socal_media'];
	collectiontitle?: string;
	onClose: () => void;
	onMenuClick: () => void;
}) => {
	const [showStickyLink, setShowStickyLink] = useState(true);

	const contentRef = useRef<HTMLDivElement>(null);
	const history = useHistory();
	const scrollTop = useScrollTop(contentRef);

	const handleSidebarClick = () => {
		history.push(`/series/${series.id}/pricelist-and-contacts`);
	};

	const handleSidebarCloseClick = () => {
		history.push(`/series/${series.id}`);
	};

	const handleOnCloseClick = () => {
		// We want to hide the sticky link immediately
		setShowStickyLink(false);
		onClose();
	};

	const stickyTransition = useTransition(showStickyLink, null, {
		from: { opacity: 0, transform: 'translate(50px, 0)' },
		leave: { opacity: 0, transform: 'translate(50px, 0)' },
		// @ts-ignore
		enter: springDelay(600)({ opacity: 1, transform: 'translate(0, 0)' }),
	});

	// Show slidein based on route to allow for back button functionality
	const routeMatchPricelistAndContacts = useRouteMatch(
		`/series/${series.id}/pricelist-and-contacts`
	);
	const showSlideIn = routeMatchPricelistAndContacts !== null;

	// Magic number here
	const isStickyDark = scrollTop > 222;

	return (
		<div className="SeriesPage" ref={contentRef}>
			{series && (
				<>
					<Hero
						series={series}
						onCloseClick={handleOnCloseClick}
						onMenuClick={onMenuClick}
					/>
					<Content contentBlocks={series.content_blocks} />
					<Footer title={collectiontitle} socialMedia={socialMedia} />
					<SlideIn show={showSlideIn} onClose={handleSidebarCloseClick}>
						<SeriesInfoSlideIn
							contacts={series.contacts}
							priceLists={series.pricelists}
						/>
					</SlideIn>
					<div className="Hero__SidebarLink">
						{stickyTransition.map(
							({ item, key, props }) =>
								item && (
									<StickyLink
										icon={<Icon name="i" width="1em" />}
										label="Pricelist and contacts"
										onClick={() => handleSidebarClick()}
										style={props}
										darkmode={isStickyDark}
										key={key}
									/>
								)
						)}
					</div>
				</>
			)}
		</div>
	);
};
