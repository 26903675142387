import { Series } from '../../api/login';
import { Icon } from '../Icon/Icon';
import './DownloadList.scss';

export const DownloadList = ({
	downloads = [],
}: {
	downloads?: Series['pricelists'];
}) => {
	if (downloads === null) {
		downloads = [];
	}
	return (
		<div className="DownloadList">
			{downloads.map(({ name, download }) => (
				<a
					className="DownloadList__Download"
					key={name}
					href={download}
					target="_blank"
					rel="noreferrer"
				>
					<div className="DownloadList__DownloadName">{name}</div>
					<div className="DownloadList__DownloadButton">
						<span>
							Download
							<Icon name="download" width={17} />
						</span>
					</div>
				</a>
			))}
			{downloads.length === 0 && (
				<div className="DownloadList__Empty">No downloads available</div>
			)}
		</div>
	);
};
