import './ThreeTripleImage.scss';

import { ZoomImage } from '../../../../components/ZoomImage/ZoomImage';

export const ThreeTripleImage = ({
	image1,
	image1_zoom,
	image2,
	image2_zoom,
	image3,
	image3_zoom,
	text,
}: {
	image1: string;
	image1_zoom: string;
	image2: string;
	image2_zoom: string;
	image3: string;
	image3_zoom: string;
	text: string;
}) => {
	return (
		<div className="ThreeTripleImage">
			<div className="ThreeTripleImage__Row">
				<div className="ThreeTripleImage__Col1">
					<div className="ThreeTripleImage__Image1">
						<ZoomImage
							image={image1}
							zoomImage={image1_zoom}
							originalWidth={500}
							originalHeight={500}
						/>
					</div>
				</div>
				<div className="ThreeTripleImage__Col2">
					<div className="ThreeTripleImage__Image2">
						<ZoomImage
							image={image2}
							zoomImage={image2_zoom}
							originalWidth={500}
							originalHeight={500}
						/>
					</div>
				</div>
			</div>
			<div className="ThreeTripleImage__Row">
				<div className="ThreeTripleImage__Col3">
					<div className="ThreeTripleImage__Image3">
						<ZoomImage
							image={image3}
							zoomImage={image3_zoom}
							originalWidth={500}
							originalHeight={500}
						/>
					</div>
				</div>
				<div className="ThreeTripleImage__Col4">
					<div
						className="ThreeTripleImage__Text"
						dangerouslySetInnerHTML={{ __html: text }}
					></div>
				</div>
			</div>
		</div>
	);
};
