import { Series } from '../../api/login';
import { ContactList } from '../ContactList/ContactList';
import { DownloadList } from '../DownloadList/DownloadList';
import { SlideInTabs } from '../SlideInTabs/SlideInTabs';
import { Tab } from '../Tabs/Tab/Tab';
import { Tabs } from '../Tabs/Tabs';

import './SeriesInfoSlideIn.scss';

export const SeriesInfoSlideIn = ({
	priceLists,
	contacts,
}: {
	priceLists: Series['pricelists'];
	contacts: Series['contacts'];
}) => {
	return (
		<div className="SeriesInfoSlideIn">
			<SlideInTabs>
				<Tabs>
					<Tab name="Pricelists">
						<div className="SeriesInfoSlideIn__Content">
							<DownloadList downloads={priceLists} />
						</div>
					</Tab>
					<Tab name="Contact">
						<div className="SeriesInfoSlideIn__Content">
							<ContactList contacts={contacts} />
						</div>
					</Tab>
				</Tabs>
			</SlideInTabs>
		</div>
	);
};
