import './MenuIcon.scss';

export const MenuIcon = () => {
	return (
		<div className="MenuIcon">
			<div className="MenuIcon__Burger">
				<div className="MenuIcon__BurgerBeef"></div>
				<div className="MenuIcon__BurgerBeef"></div>
				<div className="MenuIcon__BurgerBeef"></div>
			</div>
			<div className="MenuIcon__Label">Menu</div>
		</div>
	);
};
