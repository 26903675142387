import { useState } from 'react';
import { login, LoginReturnValue } from '../../api/login';
import { noop } from '../../utils/noop';
import { Icon } from '../Icon/Icon';
import './LoginBox.scss';

export const LoginBox = ({
	onLogin = noop,
}: {
	onLogin?: (data: LoginReturnValue) => void;
}) => {
	const [password, setPassword] = useState('');
	const [isWrong, setIsWrong] = useState(false);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// Check password and get Series data from WP
		const result = await login(password).catch(() => null);

		if (result !== null) {
			return onLogin(result);
		}

		// If not correct, wobble the login
		setIsWrong(true);
	};

	const className = `LoginBox${isWrong ? ' is-wrong' : ''}`;

	return (
		<form
			className={className}
			onSubmit={handleSubmit}
			onAnimationEnd={() => setIsWrong(false)}
		>
			<div className="LoginBox__InputBox">
				<input
					type="password"
					placeholder="Enter password"
					className="LoginBox__Input"
					value={password}
					autoComplete="current-password"
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>

			<button className="LoginBox__SubmitButton">
				<Icon name="lock" width={10} />
				Login
			</button>
		</form>
	);
};
