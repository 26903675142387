/* eslint-disable import/no-webpack-loader-syntax */
import { ReactComponent as lock } from './assets/lock.svg';
import { ReactComponent as download } from './assets/download.svg';
import { ReactComponent as i } from './assets/i.svg';

export const icons = {
	lock,
	download,
	i,
};
