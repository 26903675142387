import { ZoomImage } from '../../../../components/ZoomImage/ZoomImage';
import './EightImageAndContent.scss';

export const EightImageAndContent = ({
	image,
	image_zoom,
	title,
	subtitle,
	text,
}: {
	image: string;
	image_zoom: string;
	title: string;
	subtitle: string;
	text: string;
}) => {
	return (
		<div className="EightImageAndContent">
			<div className="EightImageAndContent__Image">
				<ZoomImage
					image={image}
					zoomImage={image_zoom}
					originalWidth={500}
					originalHeight={500}
				/>
			</div>
			<div className="EightImageAndContent__Content">
				<div
					className="EightImageAndContent__Title"
					dangerouslySetInnerHTML={{ __html: title }}
				></div>
				<div
					className="EightImageAndContent__Subtitle"
					dangerouslySetInnerHTML={{ __html: subtitle }}
				></div>
				<div className="EightImageAndContent__Line"></div>
				<div
					className="EightImageAndContent__Text"
					dangerouslySetInnerHTML={{ __html: text }}
				></div>
			</div>
		</div>
	);
};
