import { api } from './api';

interface Image {
	cropped: string;
	zoom: string;
}

export interface Contact {
	name: string;
	job_title: string;
	phone: string;
	email: string;
	workplace: string;
}

export interface PriceList {
	name: string;
	download: string;
}

interface OneImageAndText {
	acf_fc_layout: 'content_block_1_-_image_and_text';
	image: Image;
	text: string;
}

interface TwoSquareImage {
	acf_fc_layout: 'content_block_2_square_image';
	image: Image;
}

interface ThreeTripleImage {
	acf_fc_layout: 'content_block_3_triple_image';
	image_1: Image;
	image_2: Image;
	image_3: Image;
	text: string;
}

interface FourTripleImageReflected {
	acf_fc_layout: 'content_block_4_triple_image_reflected';
	image_1: Image;
	image_2: Image;
	image_3: Image;
	text: string;
}

interface FiveWideImage {
	acf_fc_layout: 'content_block_5_wide_image';
	image: Image;
}

interface SixShortDemoVideo {
	acf_fc_layout: 'content_block_7_large_text';
	text: string;
}

interface SevenLargeText {
	acf_fc_layout: 'content_block_6_short_demo_video';
	video: string;
}

interface EightImageAndContent {
	acf_fc_layout: 'content_block_8_image_and_content';
	title: string;
	subtitle: string;
	image: Image;
	text: string;
}
interface NineYoutubeVideo {
	acf_fc_layout: 'content_block_9_-_youtube';
	youtubeid: string;
	cover: Image;
	heading: string;
}

type Block =
	| OneImageAndText
	| TwoSquareImage
	| ThreeTripleImage
	| FourTripleImageReflected
	| FiveWideImage
	| SixShortDemoVideo
	| SevenLargeText
	| EightImageAndContent
	| NineYoutubeVideo;

export interface Globals {
	collection_title: string;
	privacy_policy_url: string;
	socal_media: { label: string; link: string }[];
}

export interface LoginPage {
	title: string;
	bg_video_mp4: string;
	bg_video_webm: string;
	bg_video_jpg: string;
}

export interface Series {
	id: number;
	title: string;
	subtitle: string;
	contacts: {
		local_contacts: Contact[];
		kristensen_contacts: Contact[];
	};
	pricelists: PriceList[];
	hero_image: string;
	overview: string;
	content_blocks: Block[];
}

export interface LoginReturnValue {
	globals: Globals;
	login_page: LoginPage;
	login_id: number;
	login_name: string;
	series: Series[];
}

export async function login(password: string): Promise<LoginReturnValue> {
	const result = await api
		.get('login', { params: { password } })
		.then(({ data }) => data);

	if (result.error) {
		throw new Error(result.error);
	}

	// Save the result to session storage
	sessionStorage.setItem('siteData', JSON.stringify(result));

	return result;
}
