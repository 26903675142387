import React from 'react';

export const Tab = ({
	name = '',
	children,
}: {
	name?: string;
	children: React.ReactNode;
}) => {
	return <div className="Tab">{children}</div>;
};
